const data = [
  {
    id: 1,
    text: "Home",
    url: "/",
  },
  {
    id: 2,
    text: "Oferta",
    url: "/offer/",
  },
  {
    id: 3,
    text: "Galeria",
    url: "/gallery/",
  },
  {
    id: 4,
    text: "Kontakt",
    url: "/contact/",
  },
]

export default data
